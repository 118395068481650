<template>
  <a-progress
    v-if="type === 'circle'"
    id="aProcess"
    type="circle"
    stroke-color="#52c41a"
    :stroke-width="12"
    :percent="rateNumber"
    :show-info="false"
  />
  <a-progress
    v-else
    id="aProcess"
    stroke-color="#52c41a"
    :percent="rateNumber"
    :steps="5"
    :show-info="false"
  />
</template>
<script>
import { reactive, toRefs, watch, onUnmounted } from 'vue'
export default {
  name: 'CountDown',
  props: {
    validTime: {
      type: Number,
      default: () => { return 0 }
    },
    tradeCurrency: {
      type: String,
      default: () => { return '' }
    },
    type: {
      type: String,
      default: () => { return 'circle' }
    }
  },
  setup(props, ctx) {
    let timer = null
    const state = reactive({
      validTime: 0,
      rateNumber: 0
    })

    watch(
      () => props.tradeCurrency,
      () => {
        clearInterval(timer)
        state.validTime = 0
        state.rateNumber = 0
        loadPercent()
      }
    )

    onUnmounted(() => {
      clearInterval(timer)
    })

    const loadPercent = () => {
      timer = setInterval(() => {
        state.validTime++
        if (state.validTime === props.validTime + 1) {
          clearInterval(timer)
          state.validTime = 0
          state.rateNumber = 0
          reset()
        }
        state.rateNumber = (state.validTime * 100) / props.validTime
      }, 1000)
    }

    const reset = async () => {
      await ctx.emit('refresh')
      loadPercent()
    }

    loadPercent()
    return {
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#aProcess {
  width: 100%;
  height: 100%;
}
#aProcess:deep(.ant-progress-inner) {
  width: 100% !important;
  height: 100% !important;
  font-size: 0 !important;
  display: block !important;
}
</style>
